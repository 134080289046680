import React from "react";
import {
  ListItem,
  ListItemText,
  Divider,
  ListItemAvatar,
  Avatar,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import assetTypes from "./assetTypes";
import { DbAsset } from "lib/db/Models";
import useMessage from "lib/hooks/useMessage";

const date = new DateFnsUtils();

interface Props {
  asset: DbAsset;
  onEdit: (id?: string) => void;
}

const AssetItem: React.FC<Props> = ({ asset, onEdit }) => {
  const t = useMessage();
  const Icon = assetTypes[asset.type]?.icon;

  const handleEdit = (id?: string) => () => {
    onEdit(id);
  };

  const secondary = [
    t("assets.purposeshort." + asset.purpose),
    asset.value + " €",
    asset.valueDate
      ? date.format(date.parse(asset.valueDate, "yyyy-MM-dd"), "dd/MM/yyyy")
      : "",
  ].filter(Boolean);

  return (
    <>
      <ListItem onClick={handleEdit(asset._id?.toHexString())} button>
        {Icon && (
          <ListItemAvatar>
            <Avatar style={{ backgroundColor: "#3f51b5" }}>
              <Icon />
            </Avatar>
          </ListItemAvatar>
        )}
        <ListItemText primary={asset.label} secondary={secondary.join(" - ")} />
      </ListItem>
      <Divider variant="inset" component="li" />
    </>
  );
};

export default AssetItem;
