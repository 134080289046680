import React from "react";
import Dropzone from "react-dropzone";
import {
  makeStyles,
  Theme,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  ListItemAvatar,
  CircularProgress,
} from "@material-ui/core";
import {
  CloudUpload as UploadIcon,
  DeleteForeverOutlined as DeleteIcon,
  Cancel as CancelUploadIcon,
  HourglassEmpty as WaitIcon,
  Error as ErrorIcon,
} from "@material-ui/icons";
import { StorageFile, useUploadQueue } from "lib/Storage";
import { StorageContext } from "lib/storage/StorageContext";
import { Alert } from "@material-ui/lab";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
  dropzone: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    textAlign: "center",
    cursor: "pointer",
    border: "1px solid gray",
    borderRadius: 5,
  },
  info: {
    color: "gray",
  },
}));

type onChangeCallback = (value: StorageFile[]) => void;
type onSelectCallback = (value: StorageFile) => void;

export interface FileDropProps {
  value: StorageFile[];
  path?: string;
  multiple?: boolean;
  onChange: onChangeCallback;
  onSelect?: onSelectCallback;
}

const FileDrop: React.FC<FileDropProps> = ({
  value,
  path,
  multiple,
  onChange,
  onSelect,
}) => {
  const maxFiles = multiple ? 100 : 1;
  const storageContext = React.useContext(StorageContext);
  const classes = useStyles();

  const onUpload = React.useCallback(
    (file: StorageFile) => {
      onChange([...value, file]);
    },
    [value, onChange]
  );

  const [queue, addFile, cancelFile] = useUploadQueue(
    storageContext.adapter!,
    onUpload
  );
  const available = maxFiles - (value ? value.length : 0) - queue.length;

  const handleDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > available) {
      acceptedFiles = acceptedFiles.slice(0, available);
    }
    acceptedFiles.forEach((file: File) => {
      addFile(file, path);
    });
  };

  const handleClick = (file: StorageFile) => () => {
    if (onSelect) {
      onSelect(file);
    }
  };

  const handleDelete = (idx: number) => () => {
    if (value) {
      onChange([...value.slice(0, idx), ...value.slice(idx + 1)]);
    }
  };

  const handleCancel = (uuid: string) => () => {
    cancelFile(uuid);
  };

  if (!storageContext.adapter) {
    return (
      <div className={classes.root}>
        <Alert severity="warning">
          <FormattedMessage id="components.filedrop.nostorage" />
        </Alert>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      {value && Boolean(value.length) && (
        <List dense>
          {value.map((item, idx) => (
            <ListItem key={idx} onClick={handleClick(item)} button>
              <ListItemText>{item.name}</ListItemText>
              <ListItemSecondaryAction>
                <IconButton
                  onClick={handleDelete(idx)}
                  edge="end"
                  aria-label="delete"
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      )}
      {Boolean(queue.length) && (
        <List dense>
          {queue.map(item => (
            <ListItem key={item.uuid}>
              <ListItemAvatar>
                <>
                  {item.progress > -1 && !item.error && (
                    <CircularProgress variant="static" value={item.progress} />
                  )}
                  {item.progress === -1 && !item.error && <WaitIcon />}
                  {item.error && <ErrorIcon color="secondary" />}
                </>
              </ListItemAvatar>
              <ListItemText primary={item.file.name} secondary={item.message} />
              <ListItemSecondaryAction>
                {item.progress === -1 && (
                  <IconButton
                    onClick={handleCancel(item.uuid)}
                    edge="end"
                    aria-label="cancel"
                  >
                    <CancelUploadIcon />
                  </IconButton>
                )}
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      )}
      {available > 0 && !Boolean(queue.length) && (
        <Dropzone onDrop={handleDrop}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} className={classes.dropzone}>
              <>
                <input {...getInputProps()} />
                <p>
                  <UploadIcon fontSize="large" color="action" />
                </p>
                <p className={classes.info}>
                  <FormattedMessage id="components.filedrop.hint" />
                </p>
              </>
            </div>
          )}
        </Dropzone>
      )}
    </div>
  );
};

export default FileDrop;
