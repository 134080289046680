import React from "react";
import * as Yup from "yup";
import {
  makeStyles,
  Theme,
  Dialog,
  Slide,
  DialogContent,
  DialogActions,
  Button,
  MenuItem,
  Grid,
  InputAdornment,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import {
  AllInclusive as AllIcon,
  Delete as DeleteIcon,
} from "@material-ui/icons";
import { TransitionProps } from "@material-ui/core/transitions";
import useMessage from "lib/hooks/useMessage";
import assetTypes from "./assetTypes";
import { Form } from "react-final-form";
import { makeValidate, Select, TextField } from "mui-rff";
import LoadingButton from "components/LoadingButton";
import FiltersModel from "./FiltersModel";
import assetPurposes from "./assetPurposes";

const useStyles = makeStyles((theme: Theme) => ({
  dialogContent: {
    minWidth: 500,
  },
  typeIcon: {
    color: "#4e4e4e",
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    verticalAlign: "bottom",
  },
}));

const schema = Yup.object().shape<FiltersModel>({
  type: Yup.string().required(),
  purpose: Yup.string().required(),
  areaMin: Yup.number(),
  areaMax: Yup.number(),
  dateMin: Yup.string(),
  dateMax: Yup.string(),
});

export const schemaValidate = makeValidate(schema);

const DialogTransition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  open: boolean;
  value: FiltersModel;
  onClose: (value?: FiltersModel) => void;
}

const DialogFilters: React.FunctionComponent<Props> = ({
  open,
  value,
  onClose,
}) => {
  const t = useMessage();
  const classes = useStyles();

  const typeItems = React.useMemo(() => {
    return [
      <MenuItem key="_all" value="_all">
        <AllIcon className={classes.typeIcon} />
        Tous
      </MenuItem>,
      ...Object.entries(assetTypes).map(([k, v]) => {
        const Icon = v.icon;
        return (
          <MenuItem key={k} value={k}>
            <Icon className={classes.typeIcon} />
            {t(`assets.type.${k}`)}
          </MenuItem>
        );
      }),
    ];
  }, [classes.typeIcon, t]);

  const purposeItems = React.useMemo(() => {
    return [
      <MenuItem key="_all" value="_all">
        <AllIcon className={classes.typeIcon} />
        Tous
      </MenuItem>,
      ...assetPurposes.map(v => (
        <MenuItem key={v} value={v}>
          {t(`assets.purpose.${v}`)}
        </MenuItem>
      )),
    ];
  }, [classes.typeIcon, t]);

  const handleFormSubmit = (values: Yup.Shape<object, FiltersModel>) => {
    return schemaValidate(values).then(errors => {
      if (errors && Object.values(errors).length > 0) {
        return errors;
      }
      return onClose(values);
    });
  };

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      TransitionComponent={DialogTransition}
    >
      <Form
        onSubmit={handleFormSubmit}
        initialValues={value}
        validateOnBlur={true}
      >
        {({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit} noValidate>
            <DialogTitle>{t("assets.filters")}</DialogTitle>
            <DialogContent className={classes.dialogContent}>
              <Grid container spacing={2}>
                <Grid xs={12} item>
                  <Select label={t("assets.field.type")} name="type">
                    {typeItems}
                  </Select>
                </Grid>
                <Grid xs={12} item>
                  <Select label={t("assets.field.purpose")} name="purpose">
                    {purposeItems}
                  </Select>
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    label={t("assets.filters.areamin")}
                    name="areaMin"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">m²</InputAdornment>
                      ),
                    }}
                    type="number"
                    fullWidth
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    label={t("assets.filters.areamax")}
                    name="areaMax"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">m²</InputAdornment>
                      ),
                    }}
                    type="number"
                    fullWidth
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    label={t("assets.filters.datemin")}
                    name="dateMin"
                    InputLabelProps={{ shrink: true }}
                    type="date"
                    fullWidth
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    label={t("assets.filters.datemax")}
                    name="dateMax"
                    InputLabelProps={{ shrink: true }}
                    type="date"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <IconButton
                onClick={() =>
                  handleFormSubmit({ type: "_all", purpose: "_all" })
                }
                disabled={submitting}
                color="secondary"
              >
                <DeleteIcon />
              </IconButton>
              <Button onClick={() => onClose()}>{t("cancel")}</Button>
              <LoadingButton
                variant="contained"
                color="primary"
                type="submit"
                loading={submitting}
                autoFocus
              >
                {t("apply")}
              </LoadingButton>
            </DialogActions>
          </form>
        )}
      </Form>
    </Dialog>
  );
};

export default DialogFilters;
