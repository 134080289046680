import {
  StorageInterface,
  onProgressCallback,
  StorageFile,
  getFileExtension,
  getFileBasename,
} from "../Storage";
import { Dropbox } from "dropbox";
import axios from "axios";

export default class DropBoxStorage implements StorageInterface {
  protected client: Dropbox;
  protected path: string;

  constructor(path: string, token: string) {
    this.path = path.endsWith("/") ? path : path + "/";
    this.client = new Dropbox({ accessToken: token, fetch });
  }

  public upload(
    file: File,
    path: string,
    progress: onProgressCallback
  ): Promise<string> {
    path = path.endsWith("/") ? path : path + "/";
    return new Promise<string>((res, rej) => {
      const dest = this.path + path + file.name;
      this.client
        .filesGetTemporaryUploadLink({
          commit_info: { path: dest } as any,
        })
        .then(r => {
          return axios.post(r.link, file, {
            headers: { "Content-Type": "application/octet-stream" },
            onUploadProgress: function (progressEvent) {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              progress(percentCompleted);
            },
            validateStatus: () => true,
          });
        })
        .then(r => {
          if (r.status === 200) {
            return res(dest);
          } else if (r.status === 409) {
            return Promise.reject("Le fichier existe déja");
          } else {
            return Promise.reject(`Bad return code ${r.status}`);
          }
        })
        .catch(rej);
    });
  }

  public previewUrl(file: StorageFile): Promise<string> {
    if (getFileExtension(file.name) === "pdf") {
      return this.client.filesDownload({ path: file.path }).then(r => {
        const blob = new File([(r as any).fileBlob as Blob], file.name, {
          type: "application/pdf",
        });
        return URL.createObjectURL(blob);
      });
    } else {
      return this.client.filesGetPreview({ path: file.path }).then(r => {
        const blob = new File(
          [(r as any).fileBlob as Blob],
          getFileBasename(file.name) + ".pdf",
          {
            type: ((r as any).fileBlob as Blob).type,
          }
        );
        return URL.createObjectURL(blob);
      });
    }
  }
}
