import React from "react";
import * as MapboxGl from "mapbox-gl";
import { isEqual } from "lodash";
import ReactMapboxGl, { ZoomControl, Cluster } from "react-mapbox-gl";
import { makeStyles, Theme, Paper, Fab } from "@material-ui/core";
import { FilterList as FiltersIcon, Add as AddIcon } from "@material-ui/icons";
import Geocoder from "react-mui-mapbox-geocoder";
import TopProgress from "components/TopProgress";
import useMessage from "lib/hooks/useMessage";
import { GeoLocation } from "lib/db/Models";
import clsx from "clsx";
import AssetsSideBar from "./AssetsSideBar";
import AssetRepository from "lib/db/AssetRepository";
import ClusterMarker from "./ClusterMarker";
import DataMarker from "./DataMarker";
import MapMarker from "./MapMarker";
import { useHistory } from "react-router-dom";
import DialogFilters from "./DialogFilters";
import FiltersModel from "./FiltersModel";

const accessToken =
  "pk.eyJ1IjoidGhzZHJvdW90IiwiYSI6ImNrZTFneXNvbTF1cm0yc3FpYnVlcXlkbjMifQ.kxF937MEGYcfQl2mq-o0bQ";

const geocoderApiOptions = {
  country: "fr",
  language: "fr",
  types: "place,district,locality,address",
  proximity: {
    longitude: 2.35183,
    latitude: 48.85658,
  },
};

const Map = ReactMapboxGl({
  accessToken,
});

const sideWidth = 400;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
  },
  container: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: "white",
    display: "flex",
    minHeight: 0,
  },
  side: {
    maxWidth: sideWidth,
    flexShrink: 0,
    flexBasis: "100%",
  },
  sidePaper: {
    position: "relative",
    height: "100%",
    overflow: "auto",
  },
  main: {
    overflow: "hidden",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    marginLeft: sideWidth * -1,
    background: "#FFFFFF",
  },
  mainSideOpen: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  filters: {
    flex: "0 0 40px",
  },
  map: {
    position: "relative",
    flexGrow: 1,
  },
  fabFilters: {
    position: "absolute",
    bottom: theme.spacing(3),
    left: theme.spacing(2),
    zIndex: 1000,
  },
  fabAdd: {
    position: "absolute",
    bottom: theme.spacing(3),
    right: theme.spacing(2),
    zIndex: 1000,
  },
}));

interface GeocoderResult {
  place_name?: string;
  center?: GeoLocation;
}

interface Props {
  edit?: boolean;
}

interface AssetAggregate {
  _id: [number, number];
  count: number;
  address: string;
  type: string;
}

function buildMatch(value: FiltersModel) {
  const match: any = {};
  if (value.type !== "_all") {
    match.type = value.type;
  }
  if (value.purpose !== "_all") {
    match.purpose = value.purpose;
  }
  if (value.areaMin && value.areaMax) {
    match.area = { $gte: value.areaMin, $lte: value.areaMax };
  } else if (value.areaMin) {
    match.area = { $gte: value.areaMin };
  } else if (value.areaMax) {
    match.area = { $lte: value.areaMax };
  }
  if (value.dateMin && value.dateMax) {
    match.valueDate = { $gte: value.dateMin, $lte: value.dateMax };
  } else if (value.dateMin) {
    match.valueDate = { $gte: value.dateMin };
  } else if (value.dateMax) {
    match.valueDate = { $lte: value.dateMax };
  }
  return match;
}

const initialFilters: FiltersModel = { type: "_all", purpose: "_all" };
const initialLocation: GeoLocation = [2.2770207, 48.8588377];
const initialZoom: [number] = [11];

const Assets: React.FunctionComponent<Props> = ({ edit }) => {
  edit = Boolean(edit);
  const history = useHistory();
  const [languageChanged, setLanguageChanged] = React.useState<boolean>(false);
  const [showFilters, setShowFilters] = React.useState<boolean>(false);
  const [freeAdd, setFreeAdd] = React.useState<boolean>(false);
  const [filters, setFilters] = React.useState<FiltersModel>(initialFilters);
  const [location, setLocation] = React.useState<GeoLocation>(initialLocation);
  const [zoom, setZoom] = React.useState<[number]>(initialZoom);
  const [address, setAddress] = React.useState<string>();
  const [assets, isLoading, load] =
    AssetRepository.useAggregate<AssetAggregate>([
      {
        $group: {
          _id: "$location.coordinates",
          count: { $sum: 1 },
          type: { $first: "$type" },
          address: { $first: "$address" },
        },
      },
    ]);
  const t = useMessage();
  const classes = useStyles();

  const changeMapLanguage = React.useCallback(
    (map: MapboxGl.Map) => {
      if (languageChanged) return;
      setLanguageChanged(true);
      map.getStyle().layers?.forEach((layer: any) => {
        if (layer.id.endsWith("-label")) {
          map.setLayoutProperty(layer.id, "text-field", [
            "coalesce",
            ["get", "name_fr"],
            ["get", "name"],
          ]);
        }
      });
    },
    [languageChanged]
  );

  const handleSelect = (v: GeocoderResult) => {
    if (v.center && v.place_name) {
      history.push("/assets");
      setLocation([...v.center]);
      setZoom([18]);
      setAddress(v.place_name);
      setFreeAdd(false);
    }
  };

  const handleSideClose = () => {
    setAddress(undefined);
    setFreeAdd(false);
  };

  const handleMarkerClick = (location: GeoLocation, address: string) => () => {
    history.push("/assets");
    setLocation(location);
    setZoom([18]);
    setAddress(address);
    setFreeAdd(false);
  };

  const handleAssetSaved = () => {
    load();
    setFreeAdd(false);
  };

  const handleFiltersClick = () => {
    setShowFilters(true);
  };

  const handleAddClick = () => {
    setFreeAdd(true);
  };

  const handleFiltersClose = (value?: FiltersModel) => {
    if (value) {
      setFilters(value);
      load([
        { $match: buildMatch(value) },
        {
          $group: {
            _id: "$location.coordinates",
            count: { $sum: 1 },
            type: { $first: "$type" },
            address: { $first: "$address" },
          },
        },
      ]);
    }
    setShowFilters(false);
  };

  const clusterMarker = (coordinates: GeoJSON.Position, count: number) => {
    return (
      <ClusterMarker
        count={count}
        key={JSON.stringify(coordinates)}
        coordinates={coordinates}
      />
    );
  };

  const sideOpen = (location && address) || freeAdd;
  return (
    <div className={classes.root}>
      <DialogFilters
        open={showFilters}
        value={filters}
        onClose={handleFiltersClose}
      />
      <TopProgress show={isLoading} />
      <div className={classes.container}>
        <div className={classes.side}>
          <Paper className={classes.sidePaper} variant="outlined" square>
            {sideOpen && (
              <AssetsSideBar
                location={location}
                address={address}
                edit={edit || freeAdd}
                onClose={handleSideClose}
                onAssetSaved={handleAssetSaved}
              />
            )}
          </Paper>
        </div>
        <div
          className={clsx({
            [classes.main]: true,
            [classes.mainSideOpen]: sideOpen,
          })}
        >
          <div className={classes.filters}>
            <Geocoder
              inputPlaceholder={t("assets.geocoder")}
              accessToken={accessToken}
              showLoader={true}
              onSelect={handleSelect}
              {...geocoderApiOptions}
            />
          </div>
          <div className={classes.map}>
            <Fab
              aria-label={t("assets.filters")}
              className={classes.fabFilters}
              color={isEqual(filters, initialFilters) ? "default" : "secondary"}
              onClick={handleFiltersClick}
            >
              <FiltersIcon />
            </Fab>
            {!sideOpen && (
              <Fab
                className={classes.fabAdd}
                color="primary"
                onClick={handleAddClick}
              >
                <AddIcon />
              </Fab>
            )}
            <Map
              // eslint-disable-next-line
              style="mapbox://styles/mapbox/streets-v11"
              center={location}
              zoom={zoom}
              containerStyle={{
                height: "100%",
                width: "100%",
              }}
              renderChildrenInPortal={true}
              onStyleData={changeMapLanguage}
            >
              <>
                <Cluster ClusterMarkerFactory={clusterMarker} zoomOnClick>
                  {assets.map(asset => (
                    <DataMarker
                      key={asset.address}
                      coordinates={asset._id}
                      count={asset.count}
                      type={asset.type}
                      onClick={handleMarkerClick(asset._id, asset.address)}
                    />
                  ))}
                </Cluster>
                {location && address && (
                  <MapMarker
                    active={true}
                    coordinates={location}
                    onClick={handleMarkerClick(location, address)}
                  />
                )}
              </>
              <ZoomControl />
            </Map>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Assets;
