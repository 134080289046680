import React from "react";
import { FieldRenderProps, Field } from "react-final-form";
import { FormControl, InputLabel } from "@material-ui/core";
import FileDrop, { FileDropProps } from "./FileDrop";
import { StorageFile } from "lib/Storage";

// interface WrapperProps extends FieldRenderProps<StorageFile[]> {
//   name: string;
//   label: string;
//   multiple?: boolean;
// }

type WrapperProps = FieldRenderProps<StorageFile[]> &
  Omit<FileDropProps, "value" | "onChange"> & {
    label: string;
  };

const FileDropWrapper: React.FunctionComponent<WrapperProps> = ({
  label,
  input: { onChange, value },
  ...rest
}) => {
  return (
    <FormControl fullWidth>
      <InputLabel shrink={true}>{label}</InputLabel>
      <FileDrop {...rest} value={value} onChange={onChange} />
    </FormControl>
  );
};

type Props = Omit<FileDropProps, "value" | "onChange"> & {
  name: string;
  label: string;
  path?: string;
};

export default function FileDropAdapter(props: Props) {
  const { name, multiple, ...rest } = props;
  return (
    <Field
      name={name}
      render={({ input, meta }) => (
        <FileDropWrapper
          input={input}
          meta={meta}
          name={name}
          multiple={multiple || false}
          {...rest}
        />
      )}
    />
  );
}
