import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  Description as DocumentIcon,
  AttachMoney as PaidIcon,
  MoneyOff as DueIcon,
} from "@material-ui/icons";
import {
  makeStyles,
  Theme,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Divider,
  Hidden,
} from "@material-ui/core";
import { DbInvoice, DbContact } from "lib/db/Models";
import { green, deepOrange } from "@material-ui/core/colors";
import { StorageFile } from "lib/Storage";
import { contactLabelHtml } from "lib/utils/Contact";
import { Skeleton } from "@material-ui/lab";

const date = new DateFnsUtils();

const useStyles = makeStyles((theme: Theme) => ({
  iconPaid: {
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[600],
  },
  iconDue: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
}));

type onEditCallback = (invoice: DbInvoice) => void;
type onViewCallback = (file: StorageFile, mdDown: boolean) => void;

interface Props {
  invoice: DbInvoice;
  contact?: DbContact;
  onEdit?: onEditCallback;
  onView?: onViewCallback;
}

const InvoiceItem: React.FunctionComponent<Props> = ({
  invoice,
  contact,
  onEdit,
  onView,
}) => {
  const classes = useStyles();

  const handleEdit = () => {
    if (onEdit) {
      onEdit(invoice);
    }
  };

  const handleView = (file: StorageFile, mdDown: boolean) => () => {
    if (onView) {
      onView(file, mdDown);
    }
  };

  const avatar = (
    <Avatar className={invoice.isPaid ? classes.iconPaid : classes.iconDue}>
      {invoice.isPaid ? <PaidIcon /> : <DueIcon />}
    </Avatar>
  );
  const primary = (
    <>
      {date.format(invoice.date, "dd/MM/yyyy")}
      {invoice.folder && (
        <>
          {" "}
          — <strong>{invoice.folder}</strong>
        </>
      )}
    </>
  );
  let total = `${invoice.total.toFixed(2)}€`;
  if (invoice.paid && invoice.paid < invoice.total) {
    total = `${invoice.paid.toFixed(2)}€ / ${total}`;
  }
  const secondary = (
    <>
      {invoice.contactId && (
        <>
          {contact ? (
            <>
              <span
                dangerouslySetInnerHTML={{ __html: contactLabelHtml(contact) }}
              />
              <br />
            </>
          ) : (
            <Skeleton animation="wave" />
          )}
        </>
      )}
      <strong>{invoice.ref}</strong> — {total}
    </>
  );

  return (
    <>
      <ListItem onClick={handleEdit} button>
        <ListItemAvatar>{avatar}</ListItemAvatar>
        <ListItemText primary={primary} secondary={secondary} />
        {onView && invoice.files && invoice.files.length > 0 && (
          <ListItemSecondaryAction>
            <Hidden mdDown>
              <IconButton
                color="primary"
                onClick={handleView(invoice.files[0], false)}
                edge="end"
                aria-label="view"
              >
                <DocumentIcon />
              </IconButton>
            </Hidden>
            <Hidden lgUp>
              <IconButton
                color="primary"
                onClick={handleView(invoice.files[0], true)}
                edge="end"
                aria-label="view"
              >
                <DocumentIcon />
              </IconButton>
            </Hidden>
          </ListItemSecondaryAction>
        )}
      </ListItem>
      <Divider variant="inset" component="li" />
    </>
  );
};

export default InvoiceItem;
